













































































































import {
  defineComponent, nextTick, PropType, provide, ref, toRef,
  useFetch, watch,
} from '@nuxtjs/composition-api';
import {
  SfAccordion,
  SfButton,
  SfFilter,
  SfHeading,
  SfRadio,
  SfSidebar,
} from '@storefront-ui/vue';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

import { DYNAMIC_CATEGORY_ATTRIBUTES } from '~/components/ProductsListing/config';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {
  AggregationOption, Pagination, usePageHelpers, useUiHelpers,
} from '~/composables';
import { getFilterConfig } from '~/modules/catalog/category/config/FiltersConfig';
import type { Aggregation } from '~/modules/GraphQL/types';

import { useFilters } from './useFilters';

// @TODO: Change filters order
export default defineComponent({
  name: 'CategoryFilters',
  components: {
    SkeletonLoader,
    CheckboxType: () => import('~/modules/catalog/category/components/filters/renderer/CheckboxType.vue'),
    SwatchColorType: () => import('~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue'),
    RadioType: () => import('~/modules/catalog/category/components/filters/renderer/RadioType.vue'),
    RangeType: () => import('~/modules/catalog/category/components/filters/renderer/RangeType.vue'),
    YesNoType: () => import('~/modules/catalog/category/components/filters/renderer/YesNoType.vue'),
    SfSidebar,
    SfHeading,
    SfAccordion,
    SfFilter,
    SfButton,
    SfRadio,
    DynamicCategories: () => import('~/components/DynamicCategories.vue'),
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    filters: {
      type: Array as PropType<Aggregation[]>,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDynamicCategory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { changeFilters, clearFilters } = useUiHelpers();
    const { isSearchPage } = usePageHelpers();

    const removableFilters = ref([]);
    const filters = toRef(props, 'filters');
    const isLoading = ref(true);

    const {
      selectedFilters, selectFilter, removeFilter, isFilterSelected, getRemovableFilters,
    } = useFilters();

    const updateRemovableFilters = () => {
      removableFilters.value = getRemovableFilters(filters.value, selectedFilters.value);
    };

    const doApplyFilters = () => {
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      if (window?.scroll) {
        window.scroll(0, 0);
      }
      emit('reloadProducts');
      emit('close');
    };

    const doRemoveFilter = ({ id, value }: { id: string, value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const doClearFilters = () => {
      clearFilters(false, isSearchPage.value);
      selectedFilters.value = {};
      updateRemovableFilters();
      emit('reloadProducts');
      emit('close');
    };

    const handleFilter = (filter: Aggregation, $event: AggregationOption) => {
      selectFilter(filter, $event);
      doApplyFilters();
    };

    watch(() => props.isVisible, (newValue) => {
      // disable Storefront UI's body scroll lock which is launched when :visible prop on SfSidebar changes
      // two next ticks because SfSidebar uses nextTick aswell, and we want to do something after that tick.
      if (newValue) {
        nextTick(() => nextTick(() => clearAllBodyScrollLocks()));
      }
    });

    useFetch(() => {
      updateRemovableFilters();
      isLoading.value = false;
    });

    provide('UseFiltersProvider', { isFilterSelected, selectedFilters, filters });

    return {
      handleFilter,
      doApplyFilters,
      doRemoveFilter,
      doClearFilters,
      getFilterConfig,
      isLoading,
      removableFilters,
      isSearchPage,
      DYNAMIC_CATEGORY_ATTRIBUTES,
    };
  },
});
