
































































































import {
  defineComponent, useMeta,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

import ProductTile from '~/components/ProductTile/ProductTile.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import {
  useProductWithFilters,
} from '~/composables';
import CategoryFilters from '~/modules/catalog/category/components/filters/CategoryFilters.vue';
import CategoryNavbar from '~/modules/catalog/category/components/navbar/CategoryNavbar.vue';

export default defineComponent({
  name: 'ProductsListing',
  components: {
    ProductTile,
    NoSearchResults: () => import('~/components/NoSearchResults.vue'),
    CategoryFilters,
    CategoryNavbar,
    LazyHydrate,
    SkeletonLoader,
    CategoryTile: () => import('./CategoryTile/CategoryTile.vue'),
  },
  props: {
    categoryName: {
      type: String,
      default: '',
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    useMeta({
      bodyAttrs: {
        class: 'overflow-x-visible md_overflow-x-hidden',
      },
    });

    return useProductWithFilters();
  },
});
