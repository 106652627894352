import RendererTypesEnum from '~/modules/catalog/category/components/filters/renderer/RendererTypesEnum';
import type { FilterConfigInterface } from '~/modules/catalog/category/config/FiltersConfig';

export enum FilterTypeEnum {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SWATCH_COLOR = 'swatch_color',
  YES_NO = 'yes_no',
  RANGE = 'range',
}

/**
 * Override this to add/modify filters renderers and data
 *
 * @attrCode: Magento attribute code
 * @type: internal filter type
 * @component: filter renderer component
 * @disabled: flag to disable filter on the front; disabled filter will be not rendered
 */
export default function config(): FilterConfigInterface[] {
  return [
    {
      attrCode: 'price',
      type: FilterTypeEnum.RANGE,
      component: RendererTypesEnum.RANGE,
    },
    {
      attrCode: 'print_speed_filters',
      type: FilterTypeEnum.RANGE,
      component: RendererTypesEnum.RANGE,
    },
    {
      attrCode: 'hide_out_of_stock',
    },
    {
      attrCode: 'specification_brand',
    },
    {
      attrCode: 'specification_product_type',
    },
    {
      attrCode: 'specification_brand_type',
    },
    {
      attrCode: 'all_in_one_functions',
    },
    {
      attrCode: 'duplex_functions',
    },
    {
      attrCode: 'standard_interfaces',
    },
    {
      attrCode: 'printing',
    },
    {
      attrCode: 'maximum_iso_a_series_paper',
    },
    {
      attrCode: 'colour',
    },
    {
      attrCode: 'printer_condition',
    },
    {
      attrCode: 'compatible_cartridges_available',
    },
    {
      attrCode: 'operating_systems_for_filters',
    },
  ];
}
